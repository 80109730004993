@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
:root {
  --bg-color: #e8eeee;
  --secondary: lightgray;
  --secondary-selected: blue;
  --background-secondary: white;
}

body {
  background-color: var(--bg-color);
  font-family: "Roboto", sans-serif;
}
